module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70,"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-4/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"showCaptions":true,"linkImagesToOriginal":false,"loading":"lazy","markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"Pirate Social CMS","htmlFavicon":"static/assets/logo.svg","includeRobots":false,"logo_url":"https://memegenes.com/assets/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lost Coast Hot Sauce","short_name":"Lost Coast Hot Sauce","start_url":"/pirate?user_mode=app","description":"Lost Coast Hot Sauce","background_color":"#111","lang":"en","theme_color":"#111","display":"standalone","icon":"static/assets/logo.svg","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/static/siteimages/manifest-icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/static/siteimages/manifest-icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"59d5e3a4420914d8d7be30ce06dcd42e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
